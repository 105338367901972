<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeList">
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      includeList: []
    }
  },
  watch: {
    $route(to) {
      //监听路由变化，把配置路由中keepAlive为true的name添加到include动态数组中
      const that = this;
      if (to.meta.keepAlive && that.includeList.indexOf(to.name) === -1) {
        if (to.name === 'Home') {
          that.includeList = [];
        }
        that.includeList.push(to.name);
      }
    }
  }
}
</script>
