import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {ActionBar, DropdownMenu, DropdownItem, NavBar, Button, Field, List, Cell, Icon, Popup} from 'vant';
import 'vant/lib/index.css';

const app = createApp(App);
app.use(router)
    .use(ActionBar)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(NavBar)
    .use(Button).use(Field).use(List).use(Cell).use(Icon).use(Popup);
app.mount('#app')
