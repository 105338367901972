<template>
  <van-nav-bar title="scum 控制台代码" :fixed="true" :placeholder="true" />
  <van-icon name="filter-o" size="24px" style="position: fixed;bottom:10px;left:10px;z-index: 1" @click="showFiler=true" />
  <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <van-cell v-for="item in list" :key="item" :value="item.name" :title="item.code" >
      <template #label>
        <div style="display: flex">
          <div style="flex: 1">{{ categoryMap[item.category].name }}</div>
          <div style="flex: 1">{{ categoryMap[item.subclass].name }}</div>
          <div style="flex: 1">{{ item.weight + ' kg' }}</div>
        </div>
      </template>
    </van-cell>
  </van-list>
  <van-popup v-model:show="showFiler" style="width: 100%;overflow: unset;">
      <van-dropdown-menu>
        <van-dropdown-item v-model="category" :options="categoryList" @change="categoryChange"/>
        <van-dropdown-item v-model="subclass" :options="subclassList"/>
      </van-dropdown-menu>
    <van-field v-model="keyword" label="关键词" placeholder="请输入关键词"/>
    <div style="padding: 5px 16px;">
      <van-button type="primary" block round @click="onConfirm">
        确认
      </van-button>
    </div>
  </van-popup>
</template>

<script>

import {ref} from "vue";
import $axios from "@/plugins/http";

export default {
  name: 'Home',
  setup() {
    const categoryList = ref([
      {text: '全部大类', value: ''}
    ]);
    const subclassList = ref([
      {text: '全部小类', value: ''},
    ]);
    const category = ref('');
    const subclass = ref('');
    const categorys = ref([]);
    const categoryMap = ref({});
    const keyword = ref('');
    const list = ref([]);
    const loading = ref(false)
    const finished = ref(false)
    const showFiler = ref(false)
    const total = ref(1);
    const postData = ref({page:0})

    const init = () => {
      $axios.post('/category/get-category', {}).then((rsp) => {
        categorys.value = rsp;
        categoryList.value = categorys.value.list.parent;
        categoryMap.value = categorys.value.list.all;
      })
    }

    const onLoad = () => {
      // 加载状态结束
      ++postData.value.page;
      $axios.post('/item/get-list',postData.value).then((rsp) =>{
        list.value.push(...rsp.list)
        loading.value = false;
        if (postData.value.page === 1) {
          total.value = rsp.total;
        }

        // 数据全部加载完成
        if (list.value.length >= total.value) {
          finished.value = true;
        }
      });
    }

    const onConfirm = () =>{
      if (category.value !== 0 && category.value !== '') {
        postData.value.category = category.value;
      }else {
        postData.value.category = 0;
      }
      if (subclass.value !== 0 && subclass.value !== '') {
        postData.value.subclass = subclass.value;
      }else {
        postData.value.subclass = 0;
      }
      if (keyword.value !== 0) {
        postData.value.keyword = keyword.value;
      }
      if (postData.value !== {}) {
        postData.value.page = 1;
        $axios.post('/item/get-list',postData.value).then((rsp) => {
          list.value = rsp.list;
          total.value = rsp.total;
          document.querySelector('html').scrollTop = 0
        })
      }
      showFiler.value = false;
    }

    const categoryChange = (value) => {
      subclass.value = ''
      if (value === '') {
        subclassList.value = [{text: '全部小类', value: ''}]
      } else {
        subclassList.value = categorys.value.list.child[value];
      }
    }

    init()

    return {
      categoryChange,
      onLoad,
      onConfirm,
      category,
      subclass,
      categoryList,
      subclassList,
      keyword,
      list,
      loading,
      finished,
      showFiler,
      categoryMap
    };
  }
}
</script>
<style scoped>
.van-dropdown-item.van-dropdown-item--down{
  top:48px!important;
  height: 47vh;
}
</style>
