import axios from "axios";
import router from "@/router";
import {showLoadingToast, closeToast} from 'vant'
// axios.defaults.baseURL = ''  //正式
axios.defaults.baseURL = 'https://scum-api.modelxx.ltd/' //测试

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = false;
//设置超时
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.code === 401) {
        router.push({path: 'login.html'})
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    // 错误抛到业务代码
    return Promise.resolve(error)
  }
);
export default {
  /**
   * @param {String} url
   * @param {Object} data
   * @returns Promise
   */
  post(url, data) {
    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: JSON.stringify(data),
      })
        .then(res => {
          resolve(res.data)
          closeToast()
        })
        .catch(err => {
          reject(err)
          closeToast()
        });
    })
  },

  get(url, data) {
    showLoadingToast({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          resolve(res.data)
          closeToast()
        })
        .catch(err => {
          reject(err)
          closeToast()
        })
    })
  }
};
